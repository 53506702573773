<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      disable-actions
      resource-path="feed-connectors/nod/categories"
    >


      <template v-slot:actions>
        <import/>
      </template>

      <template v-slot:item.parent="{item}">
        <template v-if="item.parent">
          <span>{{ item.parent.name }}</span>
          <template v-if="item.parent.parent">
            / {{ item.parent.parent.name }}
          </template>
        </template>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import Import from '@/views/FeedConnectors/Nod/NodCategories/Import'

export default {
  components: {
    Import,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Categoria principala',
          value: 'parent',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        }
      ]
    }
  }
}
</script>
